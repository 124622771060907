import React, { FC } from "react"

const Footer: FC<{
  fixed?: boolean
  className?: string
}> = ({ fixed = false, className = "" }) => {
  let classes = []
  if (fixed) classes = [...classes, "fixed", "bottom-0", "w-full"]
  if (className) classes = [...classes, className]
  return (
    <footer
      className={`py-8 text-sm text-center bg-gray-900 text-gray-100 ${classes.join(
        " "
      )}`}
    >
      &copy; {new Date().getFullYear()} Created by{" "}
      <a
        href="https://www.amasweb.com"
        title="Rýchly web postavený na moderných technológiách"
        className="font-bold text-white"
      >
        Michal Masliš
      </a>
    </footer>
  )
}

export default Footer
