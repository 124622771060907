import { Dispatch, SetStateAction, useEffect, useState } from "react"

// declare var window: any
// window = window || undefined
const windowIsDefined = typeof window !== "undefined"

export function useLocalStorage<S>(
  localStorageKey: string,
  initialState: S
): [S, Dispatch<SetStateAction<S>>] {
  const localStorageState = JSON.parse(
    windowIsDefined
      ? (window.localStorage.getItem(localStorageKey) as string)
      : null
  )
  // console.log(localStorageState)
  const [value, setValue] = useState(
    localStorageState != null ? localStorageState : initialState
  )

  useEffect(() => {
    const serializedState = JSON.stringify(value)

    windowIsDefined &&
      window.localStorage.setItem(localStorageKey, serializedState)
  }, [localStorageKey, value])

  return [value, setValue]
}
