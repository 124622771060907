import { useStaticQuery, graphql } from "gatsby"
interface SiteMetadata {
  title: string
  description: string
  author: string
  siteUrl: string
  logo: any
}

export const useSiteMetadata = (): SiteMetadata => {
  const { site, logo } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }

        logo: file(relativePath: { eq: "logos/farnost-sv-mikulasa-presov-logo.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return {
    title: site.siteMetadata.title,
    description: site.siteMetadata.description,
    author: site.siteMetadata.author,
    siteUrl: site.siteMetadata.siteUrl,
    logo: logo.childImageSharp.fluid,
  }
  // logo: site.siteMetadata.logo.childImageSharp.fluid,
}
