/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from "react"
import PropTypes from "prop-types"
import Footer from "./Footer"
import "../assets/styles/index.scss"
import Navbar from "./Navbar"
import CookieConsent from "react-cookie-consent"
const Layout: FC = ({ children }) => {
  const navbarItems = [
    { to: "/", label: "Úvod" },
    // { to: '/galeria', label: 'Galéria' },
    // {
    //   to: "/organ",
    //   label: "Organ",
    // },
    {
      to: "/projekty",
      label: "Projekty",
    },
    {
      to: "/verejne-obstaravanie",
      label: "Verejné obstarávanie",
    },
    {
      to: "/profil-verejneho-obstaravatela",
      label: "Profil verejného obstarávateľa",
    },
    {
      to: "https://presov.rimkat.sk",
      label: "Farská stránka",
    },
  ]

  // <Header siteTitle={title} className="" />
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar items={navbarItems} />
      <div className="flex-grow p-3">
        <main>{children}</main>
      </div>
      <Footer fixed={false} className="" />
      <CookieConsent
        location="bottom"
        buttonText="Súhlasím"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#1a202c" }}
        buttonStyle={{ color: "#fff", fontSize: "13px" }}
        expires={150}
      >
        Pre lepší online zážitok používame súbory “cookies”. Súhlasíte s
        používaním cookies a spracovaním súvisiacich osobných údajov?{" "}
        <span>
          <a
            href="https://policies.google.com/technologies/cookies?hl=sk"
            rel="nofollow noopener noreferrer"
          >
            Viac info tu
          </a>
        </span>
      </CookieConsent>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
