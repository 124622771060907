import React, { useState, FC } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import { useLocalStorage } from "../hooks/useLocalStorage"

interface NavbarItemProps {
  to: string
  label: string
}

interface MenuProps {
  items: NavbarItemProps[]
}
// <a
// 	href="#"
// 	className="md:ml-4 flex items-center justify-start md:mb-0 mb-4 cursor-pointer"
// >
// 	<img
// 		className="rounded-full w-10 h-10 border-2 border-transparent hover:border-indigo-400"
// 		src="https://pbs.twimg.com/profile_images/1128143121475342337/e8tkhRaz_normal.jpg"
// 		alt="Andy Leverenz"
// 	/>
// </a>
// const menu = ({ items }: MenuProps) => {
const menu: FC<MenuProps> = ({ items }) => {
  return (
    <div className="md:flex md:items-center md:w-auto w-full" id="menu">
      <nav>
        <ul className="md:flex items-center justify-between text-base text-gray-700 pt-4 md:pt-0">
          {items.map((item, index) => (
            <li key={index}>
              {item.to.startsWith("/") ? (
                <Link
                  to={item.to}
                  className="sm:py-1 md:p-4 px-0 block border-b-2 border-transparent hover:border-blue-700"
                >
                  {item.label}
                </Link>
              ) : (
                <a
                  href={item.to}
                  className=" sm:py-1 md:p-4 px-0 block border-b-2 border-transparent hover:border-blue-700"
                >
                  {item.label}
                </a>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}
interface NavbarProps {
  items: NavbarItemProps[]
  className?: string
}
const Navbar: FC<NavbarProps> = ({ items, className }) => {
  const [showMenu, setShowMenu] = useLocalStorage("showMenu", true)
  // const [showMenu, setShowMenu] = useState(true)
  const { title, logo, siteUrl } = useSiteMetadata()
  return (
    <header className={`bg-white flex flex-wrap items-center p-3 ${className}`}>
      <div className="flex-1 flex justify-between items-center">
        {logo && (
          <Link to="/">
            <Img
              fluid={logo}
              className="w-12 sm:w-16"
              alt={title}
              title={title}
            />
          </Link>
        )}
      </div>
      <label htmlFor="menu-toggle" className="cursor-pointer md:hidden block">
        <svg
          className="fill-current text-gray-900"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <title>menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </label>
      <input
        className="hidden"
        type="checkbox"
        id="menu-toggle"
        onChange={() => {
          setShowMenu(!showMenu)
        }}
      />
      <div className="w-full md:hidden">{showMenu && menu({ items })}</div>
      <div className="hidden md:block">{menu({ items })}</div>
    </header>
  )
}
export default Navbar
